import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface MembershipPlansMethod {
    plan_id: number;
    plan_name: string;
    plan_type: string;
    plan_amount: number;
    plan_amount_type: string;
    plan_duration: number;
    plan_contacts: number;
    profile: number;
    plan_msg: number;
    video: string;
    chat: string;
    plan_offers: string;
    status: string;
}

export interface MembershipPlansMethodInfo {
    errors: unknown;
    membershipPlansMethodInfo: MembershipPlansMethod[];
}

@Module
export default class MembershipPlansMethodModule extends VuexModule implements MembershipPlansMethodInfo {
    errors = {};
    membershipPlansMethodInfo = [] as MembershipPlansMethod[];


    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    @Mutation
        [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }


    @Action
        [Actions.GET_MEMBERSHIP_PLANS]() {
        return ApiService.query("/admin/membershipPlans", {
            params: {
                status:"Approved"
            }})
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
}
